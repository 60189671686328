<template>
  <div>
    <h1>Tests Page</h1>
    <p>This is the tests page.</p>
    <v-text-field v-model="phoneNumber" label="Phone Number" required></v-text-field>
    <v-textarea v-model="smsText" label="SMS Text" required></v-textarea>
    <v-btn @click="sendSMS">Send SMS</v-btn>
  </div>
</template>

<script>
export default {
  name: "Tests",
  data() {
    return {
      phoneNumber: '',
      smsText: ''
    };
  },
  methods: {
    sendSMS() {
      this.$store.dispatch('sendSMS', { phoneNumber: this.phoneNumber, smsText: this.smsText }) 
      .then(() => {
          this.$store.dispatch('showSnackbar', { text: 'SMS sent successfully', color: 'primary' });
      })
      .catch(error => {
          console.error('Error sending SMS:', error);
          this.$store.dispatch('showSnackbar', { text: 'Failed to send SMS', color: 'error' });
      });
    }
  }
}
</script>

<style scoped>
</style>
