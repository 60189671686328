<template>
    <nav>
        <v-app-bar :fixed=false :absolute=false app color="#383737" dark dense>
            <v-app-bar-nav-icon v-if="showDrawer && $vuetify.breakpoint.xs" @click="drawer=!drawer"></v-app-bar-nav-icon>
            <div class="d-flex align-center">
              <v-img
                  @click="$router.push({path: `/`})"
                  alt="Kootto Logo"
                  class="shrink mr-2"
                  contain
                  src="../assets/icons/Dark_logo_filled.svg"
                  transition="scale-transition"
                  width="40"
              />
                <div style="font-family: luxia;font-weight: bold;font-size: x-large" @click="$router.push({path: `/`})">{{$root.appName}}</div>
            </div>
            <v-spacer></v-spacer>
            <v-menu aria-label="Menu" id="menu" v-if="$vuetify.breakpoint.xs" v-model="showMenu" offset-y absolute class="text-center" :close-on-click="true" :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                    <v-app-bar-nav-icon  v-bind="attrs"
                                        v-on="on"
                                        aria-label="Menu">
                    </v-app-bar-nav-icon>
                </template>
                <v-list>
                  <v-list-item id="account" v-if="loggedin && !isAdmin && !isChef && !$router.currentRoute.path.includes('/account')" class="text-center" @click="showMenu=false;$router.push({path: `/account/overview`})">
                    <span class="ml-2 mr-2 pointer">Account</span>
                  </v-list-item>
                    <v-list-item id="admin" v-if="loggedin && isAdmin && !$router.currentRoute.path.includes('/admin')" class="text-center" @click="showMenu=false;$router.push({path: `/admin/overview`})">
                        <span class="ml-2 mr-2 pointer">Admin</span>
                    </v-list-item>
                    <v-list-item id="dasboard" v-if="loggedin && (isChef || isAdmin) && !$router.currentRoute.path.includes('/dashboard')" class="text-center" @click="showMenu=false;$router.push({path: `/dashboard/overview`})">
                        <span class="ml-2 mr-2 pointer">Dashboard</span>
                    </v-list-item>
                    <v-list-item  v-if="!loggedin" @click.capture="showMenu=false;openSignup(true)">
                      <span id="signup_chef" class="ml-2 mr-2 pointer">Sign Up as a Chef</span>
                    </v-list-item>
                    <v-list-item  v-if="!loggedin" @click.capture="showMenu=false;openSignup(false)">
                      <span id="signup" class="ml-2 mr-2">Signup</span>
                    </v-list-item>
                    <v-list-item id="login" v-if="!loggedin" @click.capture="openLogin()">
                        <span class="ml-2 mr-2 pointer">Login</span>
                    </v-list-item>
                    <v-list-item id="logout" v-if="loggedin" @click.capture="logout();showMenu=false">
                        <span class="ml-2 mr-2 pointer">Logout</span>
                    </v-list-item>
                  <v-list-item id="change_password" v-if="loggedin" @click.capture="$root.showChangePWD=true;showMenu=false">
                    <span class="ml-2 mr-2 pointer">Change Password</span>
                  </v-list-item>
                  <v-list-item id="email" v-if="loggedin && this.$store.state.user">
                    <span class="ml-2 mr-2 " style="color:grey">{{this.$store.state.user.email}}</span>
                  </v-list-item>
                </v-list>
            </v-menu>
            <div id="account_overview" v-if="!$vuetify.breakpoint.xs && loggedin && !isAdmin && !isChef && !routeIncludesAccount()"
                 @click="$router.push({path: `/account/overview`})"
                 text>
              <span class="ml-2 mr-2 pointer">Account</span>
            </div>
            <div id="admin_overview" v-if="!$vuetify.breakpoint.xs && loggedin && isAdmin && !routeIncludesAdmin()"
                 @click="$router.push({path: `/admin/overview`})"
                 text>
                <span class="ml-2 mr-2 pointer">Admin</span>
            </div>
            <div id="dasboard_overview" v-if="!$vuetify.breakpoint.xs && loggedin && (isChef || isAdmin) && !routeIncludesDashboard()"
                   @click="$router.push({path: `/dashboard/overview`})"
                   text>
                <span class="ml-2 mr-2 pointer">Dashboard</span>
            </div>
            <div v-if="!loggedin && !$vuetify.breakpoint.xs">
              <span id="signup_chef" @click="openSignup(true)" class="ml-2 mr-2 pointer">Sign Up as a Chef</span>
              <span id="signup" @click="openSignup(false)" class="ml-2 mr-2 pointer">Signup</span>
            </div>
<!--            <signup :chef="showSignupChef" :show="showSignup" :close-action="closeSignup"></signup>-->
            <div id="login" v-if="!$vuetify.breakpoint.xs && !loggedin"
                @click="openLogin()">
                <span class="ml-2 mr-2 pointer">Login</span>
            </div>
<!--            <login :show="showLogin" :close-action="closeLogin" ></login>-->

            <v-menu v-if="loggedin && !$vuetify.breakpoint.xs" offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn id="menu" aria-label="Menu"
                           text
                            v-bind="attrs"
                            v-on="on"
                    >
                        <v-icon>mdi-account-circle</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item id="logout" @click="logout" >
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item>
                  <v-list-item id="change_password"  @click="$root.showChangePWD=true;" >
                    <v-list-item-title>Change Password</v-list-item-title>
                  </v-list-item>
                  <v-list-item id="email" v-if="loggedin && this.$store.state.user">
                    <span class="ml-2 mr-2 " style="color:grey">{{this.$store.state.user.email}}</span>
                  </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <v-navigation-drawer
                id="dashboard"
                v-if="showDrawer && (isChef || isAdmin) && routeIncludesDashboard()"
                v-model="drawer"
                :expand-on-hover="$vuetify.breakpoint.sm"
                :mini-variant="$vuetify.breakpoint.sm"
                :right="false"
                :permanent="$vuetify.breakpoint.sm || $vuetify.breakpoint.mdAndUp"
                :disable-resize-watcher="true"
                app
                dark
        >
            <v-list
                    dense
                    nav
                    :class="{ 'warning--text py-0':this.$root.self===false,'py-0':this.$root.self===true} "
            >
                <v-list-item v-if="loggedin && user && user.profile" two-line :class="miniVariant && 'px-0'" >
                    <v-list-item-avatar>
                            <img    style="object-fit: cover;"
                                    v-if="user.profile.photoPath"
                                    :src="user.profile.photoPath+user.profile.photoFilename"
                                    :alt="user.profile.profileName"
                            />
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>{{user.profile.profileName}}</v-list-item-title>
                        <v-list-item-subtitle v-if="user.profile.status" >{{user.profile.status.name!=="APPROVED"?user.profile.status.displayName:""}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item
                        v-for="item in itemsDashboard"
                        :key="item.title"
                        link
                        :to="item.link"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-navigation-drawer
          id="account"
          v-if="showDrawer && (!isChef) && routeIncludesAccount()"
          v-model="drawer"
          :expand-on-hover="$vuetify.breakpoint.sm"
          :mini-variant="$vuetify.breakpoint.sm"
          :right="false"
          :permanent="$vuetify.breakpoint.sm || $vuetify.breakpoint.mdAndUp"
          :disable-resize-watcher="true"
          app
          dark

      >
        <v-list
            dense
            nav
            :class="{ 'warning--text  py-0':this.$root.self===false,'py-0':this.$root.self===true} "
        >
          <v-list-item v-if="loggedin && user && user.userDetails" two-line :class="miniVariant && 'px-0'" >
            <v-list-item-content>
              <v-list-item-title>{{user.userDetails.firstname}} {{user.userDetails.lastname}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item
              v-for="item in itemsAccount"
              :key="item.title"
              link
              :to="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
        <v-navigation-drawer
                id="admin"
                v-if="showDrawer && isAdmin && routeIncludesAdmin()"
                v-model="drawer"
                :expand-on-hover="$vuetify.breakpoint.sm"
                :mini-variant="$vuetify.breakpoint.sm"
                :right="false"
                :permanent="$vuetify.breakpoint.sm || $vuetify.breakpoint.mdAndUp"
                :disable-resize-watcher="true"
                app
                dark
        >
            <v-list
                    dense
                    nav
                    class="py-0"
            >
                <v-list-item v-if="loggedin && user && user.profile" two-line :class="miniVariant && 'px-0'" >
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item
                        v-for="item in itemsAdmin"
                        :key="item.title"
                        link
                        :to="item.link"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </nav>
</template>

<script>
    // import '../assets/css/materialdesignicons.min.css';
    // /*const mdCss =*/ () => import(/* webpackChunkName: "nav" */ '../assets/css/materialdesignicons.min.css');
    // import Login from '../components/Login';
    // import Signup from '../components/Signup';
    import {mapGetters} from 'vuex';
    import { mapMutations } from "vuex";
    export default {
        name: "Navigation",
        props: {
            showDrawer: Boolean,
            // drawer:Boolean
        },
        // components: {Login,Signup},
        data() {
            return {
                // loggedin:this.$store.state.loggedin,
                showLogedinMenu:false,
                showMenu:false,
                loginDialog:false,
                successLogin:false,
                email:undefined,
                password:undefined,
                drawer:false,
                itemsDashboard: [
                    { title: 'Dashboard', icon: 'mdi-view-dashboard' ,link:'overview' },
                    { title: 'Bookings', icon: 'mdi-receipt',link:'bookings' },
                    { title: 'Details', icon: 'mdi-card-account-details',link:'details' },
                    { title: 'Profile', icon: 'mdi-clipboard-account',link:'profile' },
                    { title: 'Documents', icon: 'mdi-file-document',link:'documents' },
                    { title: 'Menus', icon: 'mdi-silverware-variant' ,link:'menus' },
                    { title: 'Photos', icon: 'mdi-image',link:'photos' },
                    { title: 'Bank', icon: 'mdi-bank',link:'bank' },
                    { title: 'Company', icon: 'mdi-factory',link:'company' },
                ],
                itemsAdmin: [
                    { title: 'Admin', icon: 'mdi-view-dashboard' ,link:'overview' },
                    { title: 'Chefs', icon: 'mdi-card-account-details',link:'chefs' },
                    { title: 'Clients', icon: 'mdi-card-account-details',link:'clients' },
                    { title: 'Potential Customers', icon: 'mdi-account-multiple-plus-outline', link: 'potentialCustomers' },
                    { title: 'Bookings', icon: 'mdi-receipt',link:'bookings' },
                    { title: 'Parameters', icon: 'mdi-database',link:'parameters' },
                    { title: 'Tests', icon: 'mdi-test-tube', link: 'tests' },
                ],
                itemsAccount: [
                  { title: 'Account', icon: 'mdi-view-dashboard' ,link:'overview' },
                  { title: 'Details', icon: 'mdi-card-account-details',link:'details' },
                  { title: 'Bookings', icon: 'mdi-receipt',link:'bookings' },
                ],
                right: false,
                permanent: false,
                miniVariant: true,
                expandOnHover: false,
                background: false,
                clipped:true
            };
        },
        computed: {
            ...mapGetters([
                'findChef',
                'isLoaded'
            ]),
            chefId() {
                // console.log(this.$route.params.id)
                return this.$route.params.id;
            },
            chef() {
                let chef = this.findChef(`${this.chefId}`);
                // console.log(chef);
                return chef;
            },
            loggedin(){
                // console.log(this.$store.state.loggedin);
                return this.$store.state.loggedin;
            },
            user() {
                if (this.$store.state.loggedin && this.$store.state.isAdmin && this.$route.params.id!==undefined ){
                    this.log("admin dashboard");
                    if (this.$store.state.workingUser!==undefined /*&& this.$store.state.workingUser.id===parseInt(this.$route.params.id)*/ ){
                        return this.$store.state.workingUser;
                    }else{
                        // this.$store.dispatch('getUser', this.$route.params.id);
                        return undefined;
                    }
                }
                return this.$store.getters.getUser;
            },

        },
        methods: {
            ...mapMutations(["showSnackbar", "closeSnackbar"]),
            logout(){
                let _this = this
                this.$store.dispatch('logout').then(function () {
                    _this.showSnackbar({ text: "You are logged out!" ,color:"primary"})
                    _this.$router.replace('/');
                }).catch(function (value) {
                        // show error
                        this.log(value);
                    }
                );
            },
            changePWD(){
              let _this = this
              this.$store.dispatch('logout').then(function () {
                _this.showSnackbar({ text: "You are logged out!" ,color:"primary"})
              }).catch(function (value) {
                    // show error
                    this.log(value);
                  }
              );
            },
            routeIncludesAccount(){
              return this.$router.currentRoute.path.includes('/account');
            },
            routeIncludesAdmin(){
                return this.$router.currentRoute.path.includes('/admin');
            },
            routeIncludesDashboard(){
                return this.$router.currentRoute.path.includes('/dashboard');
            },
          openLogin(){
            this.$root.showLogin=true;
            let screenview={
              app_name: process.env.VUE_APP_ANALYTICS_NAME,
              screen_name: 'Login',
              page_title: 'Kootto - Login',
            };
            if (localStorage && localStorage.getItem("campaign")!==undefined){
              screenview.campaign=localStorage.getItem("campaign");
            }
            this.$gtag.screenview(screenview);
          },
        },
        watch: {
            // '$vuetify.breakpoint.sm': function (value) {
            //     if (value){
            //         this.permanent = true;
            //         this.miniVariant = true;
            //         this.expandOnHover = true;
            //     }
            //     console.log(value);
            // },
        },


    }
</script>

<style scoped>

</style>
